<template>
  <div>
    <v-row>
      <v-col class="text-right pb-0">
        <h2 v-if="isInvoice">INVOICE</h2>
        <h2 v-else>QUOTATION</h2>
      </v-col>
    </v-row>
    <!-- Workshop Info -->
    <v-row>
      <v-col class="text-left">
        <h3>{{ quoteModel.workshop.name }}</h3>
        <p>
          {{ quoteModel.workshop.address.streetNumber }}
          {{ quoteModel.workshop.address.streetName }}
          <br />
          {{ quoteModel.workshop.address.suburb }}
          <br />
          {{ quoteModel.workshop.address.city }}
          <br />
          {{ quoteModel.workshop.address.province }}
          <br />
          {{ quoteModel.workshop.address.country }}
          <br />
          {{ quoteModel.workshop.address.postalCode }}
        </p>
      </v-col>
      <v-col class="text-right">
        <table
          v-if="isInvoice"
          class="quotes-table float-right text-center mt-2"
        >
          <tr>
            <th>Reference</th>
          </tr>
          <tr v-if="quoteModel.invoiceNumber">
            <td>
              {{ quoteModel.invoiceNumber }}
            </td>
          </tr>
          <tr>
            <td>
              {{ referenceProp }}
            </td>
          </tr>
          <tr v-if="quoteModel.externalReference">
            <td>
              {{ quoteModel.externalReference }}
            </td>
          </tr>
          <tr>
            <th>Invoice date</th>
          </tr>
          <tr>
            <td>{{ dateHelper.formatDateShort(invoiceCompletedDate) }}</td>
          </tr>
          <tr>
            <th>Reference</th>
          </tr>
          <tr>
            <td>{{ referenceProp }}</td>
          </tr>
        </table>
        <table v-else class="quotes-table float-right text-center mt-2">
          <tr>
            <th>Reference</th>
          </tr>
          <tr>
            <td>
              {{ referenceProp }}
            </td>
          </tr>
          <tr v-if="quoteModel.externalReference">
            <td>
              {{ quoteModel.externalReference }}
            </td>
          </tr>
          <tr>
            <th>Date received</th>
          </tr>
          <tr>
            <td>{{ dateHelper.formatDateShort(quoteModel.dateCreated) }}</td>
          </tr>
          <tr>
            <th>Valid until</th>
          </tr>
          <tr>
            <td>
              {{ dateHelper.formatDateShort(quoteModel.expiryDate) }}
              (~
              {{
                dateHelper.dateDifferenceFromToday(
                  quoteModel.expiryDate,
                  "days"
                )
              }}
              days)
            </td>
          </tr>
          <tr>
            <th>Service date</th>
          </tr>
          <tr>
            <td>{{ dateHelper.formatDateShort(quoteModel.serviceDate) }}</td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <!-- Customer Info -->
    <v-row>
      <v-col class="text-left">
        <table class="quotes-table float-left">
          <tr>
            <th>Customer info</th>
          </tr>
          <tr>
            <td>
              <span v-if="userProp.businessName">
                <strong>{{ userProp.businessName }}</strong>
                <br />
              </span>
              <span v-if="userProp.businessRegistrationNumber">
                Reg: {{ userProp.businessRegistrationNumber }}
                <br />
              </span>
              <span v-if="userProp.businessTaxNumber">
                VAT: {{ userProp.businessTaxNumber }}
                <br />
              </span>

              {{ `${userProp.firstName} ${userProp.lastName}` }}
              <br />
              {{ userProp.phoneWithoutPrefix }}
              <br />
              {{ userProp.email }}
              <br />
            </td>
          </tr>
        </table>
        <table
          v-if="
            userProp.businessName &&
            userProp.businessAddress &&
            userProp.businessAddress.id != 0
          "
          class="quotes-table float-left"
          :class="$vuetify.breakpoint.smAndUp ? 'ml-4' : ''"
        >
          <tr>
            <th>Customer address</th>
          </tr>
          <tr>
            <td>
              {{ userProp.businessAddress.streetNumber }}
              {{ userProp.businessAddress.streetName }}
              <br />
              {{ userProp.businessAddress.suburb }}
              <br />
              {{ userProp.businessAddress.city }}
              <br />
              {{ userProp.businessAddress.province }}
              <br />
              {{ userProp.businessAddress.country }}
              <br />
              {{ userProp.businessAddress.postalCode }}
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <!-- Quotes Tables -->
    <v-row>
      <v-col>
        <table class="display-table">
          <colgroup>
            <col />
            <col v-if="!hidePriceProp" />
            <col style="width: 150px" v-if="!hidePriceProp" />
            <col style="width: 150px" />
          </colgroup>
          <thead class="display-table-header">
            <tr class="display-1">
              <th class="text-start subtitle-2">
                <span>Description</span>
              </th>
              <th class="text-end subtitle-2" v-if="!hidePriceProp">
                <span>Unit Price</span>
              </th>
              <th class="text-center subtitle-2">
                <span>Quantity</span>
              </th>
              <th class="text-end subtitle-2" v-if="!hidePriceProp">
                <span>Total</span>
              </th>
            </tr>
          </thead>
          <tbody
            v-for="item in quoteModel.quoteRequestItems"
            v-bind:key="item.id"
          >
            <tr
              v-if="item.quoteItems.length == 0 && showItemBody(item)"
              class="error-class-spacer empty-wrapper"
            >
              <td colspan="4"></td>
            </tr>
            <tr
              class="display-table-group-header"
              :class="getHeadingClass(item)"
              v-if="showItemBody(item)"
            >
              <td class="text-left" colspan="4">
                <strong>Requested:</strong>
                {{ item.name }}
                <span class="float-right" v-if="!hidePriceProp">
                  {{ getQuoteRequestItemTotal(item) | currency }}
                </span>
              </td>
            </tr>
            <template
              v-if="
                item.quoteRequestItemNotes &&
                item.quoteRequestItemNotes.length > 0
              "
            >
              <tr
                :class="getHeadingClass(item)"
                v-for="(qrin, index) in item.quoteRequestItemNotes"
                :key="`qa_${index}`"
              >
                <th class="text-center" colspan="5">
                  {{ qrin.note }}
                </th>
              </tr>
            </template>
            <tr
              v-for="quoteItem in item.quoteItems"
              v-bind:key="quoteItem.id"
              :class="getAmendmentTypeBodyClass(item, quoteItem)"
            >
              <td class="text-start" v-if="showItem(item, quoteItem)">
                {{ getQuoteItemDescription(quoteItem) }}
                <div class="workshop-note" v-if="quoteItem.note">
                  <span>{{ quoteItem.note }}</span>
                </div>
              </td>
              <td
                class="text-end nowrap"
                v-if="!hidePriceProp && showItem(item, quoteItem)"
              >
                <div
                  v-if="
                    quoteItem.amendmentStatus == amendmentStatuses.pending &&
                    quoteItem.previousPriceIncl !== null
                  "
                >
                  <v-slide-x-transition
                    group
                    mode="in-out"
                    origin="center center"
                  >
                    <div v-if="item.amendmentAccepted" key="price-0">
                      {{ quoteItem.priceIncl | currency }}
                    </div>
                    <div v-else key="price-1">
                      {{ quoteItem.previousPriceIncl | currency }}
                    </div>
                  </v-slide-x-transition>
                </div>
                <div v-else>
                  {{ quoteItem.priceIncl | currency }}
                </div>
              </td>
              <td class="text-center nowrap" v-if="showItem(item, quoteItem)">
                <div
                  v-if="
                    quoteItem.amendmentStatus == amendmentStatuses.pending &&
                    quoteItem.previousQuantity !== null
                  "
                >
                  <v-slide-x-transition
                    group
                    mode="in-out"
                    origin="center center"
                  >
                    <div v-if="item.amendmentAccepted" key="qty-0">
                      {{ quoteItem.quantity }}
                    </div>
                    <div v-else key="qty-1">
                      {{ quoteItem.previousQuantity }}
                    </div>
                  </v-slide-x-transition>
                </div>
                <div v-else>
                  {{ quoteItem.quantity }}
                </div>
              </td>
              <td
                class="text-end nowrap"
                v-if="!hidePriceProp && showItem(item, quoteItem)"
              >
                <div
                  v-if="
                    quoteItem.amendmentStatus == amendmentStatuses.pending &&
                    quoteItem.totalIncl !== quoteItem.verifiedTotalIncl
                  "
                >
                  <v-slide-x-transition
                    group
                    mode="in-out"
                    origin="center center"
                  >
                    <div v-if="item.amendmentAccepted" key="total-0">
                      {{ quoteItem.totalIncl | currency }}
                    </div>
                    <div v-else key="total-1">
                      {{ quoteItem.verifiedTotalIncl | currency }}
                    </div>
                  </v-slide-x-transition>
                </div>
                <div v-else>
                  {{ quoteItem.totalIncl | currency }}
                </div>
              </td>
            </tr>
            <tr
              v-if="item.labourItem && item.labourItem.verifiedTotalIncl > 0"
              :class="getAmendmentTypeBodyClass(item, item.labourItem)"
            >
              <td class="text-start" v-if="showItem(item, item.labourItem)">
                {{ getQuoteItemDescription(item.labourItem) }}
                <div class="workshop-note" v-if="item.labourItem.note">
                  <span>{{ item.labourItem.note }}</span>
                </div>
              </td>
              <td
                class="text-end nowrap"
                v-if="!hidePriceProp && showItem(item, item.labourItem)"
              >
                <div
                  v-if="
                    item.labourItem.amendmentStatus ==
                      amendmentStatuses.pending &&
                    item.labourItem.previousPriceIncl !== null
                  "
                >
                  <v-slide-x-transition
                    group
                    mode="in-out"
                    origin="center center"
                  >
                    <div v-if="item.amendmentAccepted" key="price-0">
                      {{ item.labourItem.priceIncl | currency }}
                    </div>
                    <div v-else key="price-1">
                      {{ item.labourItem.previousPriceIncl | currency }}
                    </div>
                  </v-slide-x-transition>
                </div>
                <div v-else>
                  {{ item.labourItem.priceIncl | currency }}
                </div>
              </td>
              <td
                class="text-center nowrap"
                v-if="showItem(item, item.labourItem)"
              >
                <div
                  v-if="
                    item.labourItem.amendmentStatus ==
                      amendmentStatuses.pending &&
                    item.labourItem.previousQuantity !== null
                  "
                >
                  <v-slide-x-transition
                    group
                    mode="in-out"
                    origin="center center"
                  >
                    <div v-if="item.amendmentAccepted" key="qty-0">
                      {{ item.labourItem.quantity }}
                    </div>
                    <div v-else key="qty-1">
                      {{ item.labourItem.previousQuantity }}
                    </div>
                  </v-slide-x-transition>
                </div>
                <div v-else>
                  {{ item.labourItem.quantity }}
                </div>
              </td>
              <td
                class="text-end nowrap"
                v-if="!hidePriceProp && showItem(item, item.labourItem)"
              >
                <div
                  v-if="
                    item.labourItem.amendmentStatus ==
                      amendmentStatuses.pending &&
                    item.labourItem.totalIncl !==
                      item.labourItem.verifiedTotalIncl
                  "
                >
                  <v-slide-x-transition
                    group
                    mode="in-out"
                    origin="center center"
                  >
                    <div v-if="item.amendmentAccepted" key="total-0">
                      {{ item.labourItem.totalIncl | currency }}
                    </div>
                    <div v-else key="total-1">
                      {{ item.labourItem.verifiedTotalIncl | currency }}
                    </div>
                  </v-slide-x-transition>
                </div>
                <div v-else>
                  {{ item.labourItem.totalIncl | currency }}
                </div>
              </td>
            </tr>
            <tr
              v-if="hasPendingAmendment(item) && showItemBody(item)"
              :class="getFooterClass(item)"
            >
              <td colspan="4" class="text-right">
                <v-btn
                  color="success"
                  rounded
                  class="mr-3"
                  @click="item.amendmentAccepted = true"
                >
                  Accept
                </v-btn>
                <v-btn
                  color="error"
                  rounded
                  @click="item.amendmentAccepted = false"
                >
                  Decline
                </v-btn>
              </td>
            </tr>
            <tr
              v-if="hasPendingAmendment(item) && showItemBody(item)"
              class="error-class-spacer empty-wrapper"
            >
              <td colspan="4"></td>
            </tr>
            <tr
              v-if="item.quoteItems.length == 0 && showItemBody(item)"
              class="error-class-body"
            >
              <td class="text-start" colspan="4">
                Workshop cannot perform this service
              </td>
            </tr>
            <tr
              v-if="item.quoteItems.length == 0 && showItemBody(item)"
              class="error-class-spacer empty-wrapper"
            >
              <td colspan="4"></td>
            </tr>
          </tbody>
          <tbody v-if="additionalItems.length > 0">
            <tr class="display-table-group-header">
              <td class="text-left" colspan="4">
                <strong>Additional complimentary</strong>
              </td>
            </tr>
            <tr
              v-for="additionalItem in additionalItems"
              v-bind:key="additionalItem.id"
            >
              <td class="text-start">{{ additionalItem.description }}</td>
              <td class="text-end" v-if="!hidePriceProp"></td>
              <td class="text-center"></td>
              <td class="text-end" v-if="!hidePriceProp">
                {{ additionalItem.totalIncl | currency }}
              </td>
            </tr>
          </tbody>
          <tfoot v-if="!hidePriceProp">
            <tr
              v-if="
                consumablesItem && _.get(consumablesItem, '[0].totalIncl') > 0
              "
            >
              <td colspan="2"></td>
              <th class="bordered-top text-end subtitle-2 midGrey--text">
                Consumables
              </th>
              <td class="bordered-top text-end primary--text font-weight-black">
                {{ _.get(consumablesItem, "[0].totalIncl") | currency }}
              </td>
            </tr>
            <tr v-if="adminFeeItem && _.get(adminFeeItem, '[0].totalIncl') > 0">
              <td colspan="2"></td>
              <th class="bordered-top text-end subtitle-2 midGrey--text">
                Admin Fee
              </th>
              <td class="bordered-top text-end primary--text font-weight-black">
                {{ _.get(adminFeeItem, "[0].totalIncl") | currency }}
              </td>
            </tr>
            <tr v-if="quoteModel.isVatRegistered">
              <td colspan="2"></td>
              <th class="bordered-top text-end subtitle-2 midGrey--text">
                VAT
              </th>
              <td class="bordered-top text-end primary--text font-weight-black">
                {{ totalVat | currency }}
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <th
                class="bordered-top bordered-bottom text-end subtitle-2 midGrey--text"
              >
                Total
              </th>
              <td
                class="bordered-top bordered-bottom text-end primary--text font-weight-black"
              >
                {{ getQuoteTotal() | currency }}
              </td>
            </tr>
          </tfoot>
        </table>

        <table class="display-table-mobile">
          <tbody
            v-for="item in quoteModel.quoteRequestItems"
            v-bind:key="item.id"
          >
            <tr
              class="display-table-group-header"
              :class="getHeadingClass(item)"
              v-if="showItemBody(item)"
            >
              <td class="text-left" colspan="2">
                <strong>Requested:</strong>
                {{ item.name }}
                <span class="float-right" v-if="!hidePriceProp">{{
                  getQuoteRequestItemTotal(item) | currency
                }}</span>
              </td>
            </tr>
            <template
              v-if="
                item.quoteRequestItemNotes &&
                item.quoteRequestItemNotes.length > 0
              "
            >
              <tr
                :class="getHeadingClass(item)"
                v-for="(qrin, index) in item.quoteRequestItemNotes"
                :key="`qa_${index}`"
              >
                <th
                  class="text-center"
                  colspan="2"
                  style="border-bottom: 1px solid #000"
                >
                  {{ qrin.note }}
                </th>
              </tr>
            </template>
            <template v-for="quoteItem in item.quoteItems">
              <tr
                v-bind:key="quoteItem.id + '_1'"
                :class="getAmendmentTypeBodyClass(item, quoteItem)"
              >
                <td v-if="showItem(item, quoteItem)">
                  <strong>Description:</strong>
                  <br />
                  {{ getQuoteItemDescription(quoteItem) }}
                  <div class="workshop-note" v-if="quoteItem.note">
                    <span>{{ quoteItem.note }}</span>
                  </div>
                </td>
                <td
                  class="nowrap"
                  v-if="!hidePriceProp && showItem(item, quoteItem)"
                >
                  <strong>Unit Price:</strong>
                  <br />
                  <div
                    v-if="
                      quoteItem.amendmentStatus == amendmentStatuses.pending &&
                      quoteItem.previousPriceIncl !== null
                    "
                  >
                    <v-slide-x-transition
                      hide-on-leave
                      group
                      mode="in-out"
                      origin="center center"
                    >
                      <div v-if="item.amendmentAccepted" key="price-0">
                        {{ quoteItem.priceIncl | currency }}
                      </div>
                      <div v-else key="price-1">
                        {{ quoteItem.previousPriceIncl | currency }}
                      </div>
                    </v-slide-x-transition>
                  </div>
                  <div v-else>
                    {{ quoteItem.priceIncl | currency }}
                  </div>
                </td>
                <td v-if="hidePriceProp && showItem(item, quoteItem)">
                  <strong>Quantity:</strong>
                  <br />
                  <div
                    v-if="
                      quoteItem.amendmentStatus == amendmentStatuses.pending &&
                      quoteItem.previousQuantity !== null
                    "
                  >
                    <v-slide-x-transition
                      hide-on-leave
                      group
                      mode="in-out"
                      origin="center center"
                    >
                      <div v-if="item.amendmentAccepted" key="qty-0">
                        {{ quoteItem.quantity }}
                      </div>
                      <div v-else key="qty-1">
                        {{ quoteItem.previousQuantity }}
                      </div>
                    </v-slide-x-transition>
                  </div>
                  <div v-else>
                    {{ quoteItem.quantity }}
                  </div>
                </td>
              </tr>
              <tr
                class="display-table-row"
                v-bind:key="quoteItem.id + '_2'"
                :class="getAmendmentTypeBodyClass(item, quoteItem)"
                v-if="!hidePriceProp"
              >
                <td class="nowrap" v-if="showItem(item, quoteItem)">
                  <strong>Quantity:</strong>
                  <br />
                  <div
                    v-if="
                      quoteItem.amendmentStatus == amendmentStatuses.pending &&
                      quoteItem.previousQuantity !== null
                    "
                  >
                    <v-slide-x-transition
                      hide-on-leave
                      group
                      mode="in-out"
                      origin="center center"
                    >
                      <div v-if="item.amendmentAccepted" key="qty-0">
                        {{ quoteItem.quantity }}
                      </div>
                      <div v-else key="qty-1">
                        {{ quoteItem.previousQuantity }}
                      </div>
                    </v-slide-x-transition>
                  </div>
                  <div v-else>
                    {{ quoteItem.quantity }}
                  </div>
                </td>
                <td class="nowrap" v-if="showItem(item, quoteItem)">
                  <strong>Total:</strong>
                  <br />
                  <div
                    v-if="
                      quoteItem.amendmentStatus == amendmentStatuses.pending &&
                      quoteItem.totalIncl !== quoteItem.verifiedTotalIncl
                    "
                  >
                    <v-slide-x-transition
                      hide-on-leave
                      group
                      mode="in-out"
                      origin="center center"
                    >
                      <div v-if="item.amendmentAccepted" key="total-0">
                        {{ quoteItem.totalIncl | currency }}
                      </div>
                      <div v-else key="total-1">
                        {{ quoteItem.verifiedTotalIncl | currency }}
                      </div>
                    </v-slide-x-transition>
                  </div>
                  <div v-else>
                    {{ quoteItem.totalIncl | currency }}
                  </div>
                </td>
              </tr>
            </template>

            <template
              v-if="item.labourItem && item.labourItem.verifiedTotalIncl > 0"
            >
              <tr
                v-bind:key="item.labourItem.id + '_1'"
                :class="getAmendmentTypeBodyClass(item, item.labourItem)"
              >
                <td v-if="showItem(item, item.labourItem)">
                  <strong>Description:</strong>
                  <br />
                  {{ getQuoteItemDescription(item.labourItem) }}
                  <div class="workshop-note" v-if="item.labourItem.note">
                    <span>{{ item.labourItem.note }}</span>
                  </div>
                </td>
                <td
                  class="nowrap"
                  v-if="!hidePriceProp && showItem(item, item.labourItem)"
                >
                  <strong>Unit Price:</strong>
                  <br />
                  <div
                    v-if="
                      item.labourItem.amendmentStatus ==
                        amendmentStatuses.pending &&
                      item.labourItem.previousPriceIncl !== null
                    "
                  >
                    <v-slide-x-transition
                      hide-on-leave
                      group
                      mode="in-out"
                      origin="center center"
                    >
                      <div v-if="item.amendmentAccepted" key="price-0">
                        {{ item.labourItem.priceIncl | currency }}
                      </div>
                      <div v-else key="price-1">
                        {{ item.labourItem.previousPriceIncl | currency }}
                      </div>
                    </v-slide-x-transition>
                  </div>
                  <div v-else>
                    {{ item.labourItem.priceIncl | currency }}
                  </div>
                </td>
                <td v-if="hidePriceProp && showItem(item, item.labourItem)">
                  <strong>Quantity:</strong>
                  <br />
                  <div
                    v-if="
                      item.labourItem.amendmentStatus ==
                        amendmentStatuses.pending &&
                      item.labourItem.previousQuantity !== null
                    "
                  >
                    <v-slide-x-transition
                      hide-on-leave
                      group
                      mode="in-out"
                      origin="center center"
                    >
                      <div v-if="item.amendmentAccepted" key="qty-0">
                        {{ item.labourItem.quantity }}
                      </div>
                      <div v-else key="qty-1">
                        {{ item.labourItem.previousQuantity }}
                      </div>
                    </v-slide-x-transition>
                  </div>
                  <div v-else>
                    {{ item.labourItem.quantity }}
                  </div>
                </td>
              </tr>
              <tr
                class="display-table-row"
                v-bind:key="item.labourItem.id + '_2'"
                :class="getAmendmentTypeBodyClass(item, item.labourItem)"
                v-if="!hidePriceProp"
              >
                <td class="nowrap" v-if="showItem(item, item.labourItem)">
                  <strong>Quantity:</strong>
                  <br />
                  <div
                    v-if="
                      item.labourItem.amendmentStatus ==
                        amendmentStatuses.pending &&
                      item.labourItem.previousQuantity !== null
                    "
                  >
                    <v-slide-x-transition
                      hide-on-leave
                      group
                      mode="in-out"
                      origin="center center"
                    >
                      <div v-if="item.amendmentAccepted" key="qty-0">
                        {{ item.labourItem.quantity }}
                      </div>
                      <div v-else key="qty-1">
                        {{ item.labourItem.previousQuantity }}
                      </div>
                    </v-slide-x-transition>
                  </div>
                  <div v-else>
                    {{ item.labourItem.quantity }}
                  </div>
                </td>
                <td class="nowrap" v-if="showItem(item, item.labourItem)">
                  <strong>Total:</strong>
                  <br />
                  <div
                    v-if="
                      item.labourItem.amendmentStatus ==
                        amendmentStatuses.pending &&
                      item.labourItem.totalIncl !==
                        item.labourItem.verifiedTotalIncl
                    "
                  >
                    <v-slide-x-transition
                      hide-on-leave
                      group
                      mode="in-out"
                      origin="center center"
                    >
                      <div v-if="item.amendmentAccepted" key="total-0">
                        {{ item.labourItem.totalIncl | currency }}
                      </div>
                      <div v-else key="total-1">
                        {{ item.labourItem.verifiedTotalIncl | currency }}
                      </div>
                    </v-slide-x-transition>
                  </div>
                  <div v-else>
                    {{ item.labourItem.totalIncl | currency }}
                  </div>
                </td>
              </tr>
            </template>

            <tr
              v-if="hasPendingAmendment(item) && showItemBody(item)"
              :class="getFooterClass(item)"
            >
              <td colspan="4" class="text-right pt-1">
                <v-btn
                  color="success"
                  rounded
                  class="mr-3"
                  @click="item.amendmentAccepted = true"
                >
                  Accept
                </v-btn>
                <v-btn
                  color="error"
                  rounded
                  @click="item.amendmentAccepted = false"
                >
                  Decline
                </v-btn>
              </td>
            </tr>
            <tr
              v-if="item.quoteItems.length == 0 && showItemBody(item)"
              class="error-class-body"
            >
              <td class="text-start" colspan="2">
                Workshop cannot perform this service
              </td>
            </tr>
          </tbody>
          <tbody v-if="additionalItems.length > 0">
            <tr class="display-table-group-header">
              <td class="text-left" colspan="2">
                <strong>Additional complimentary</strong>
              </td>
            </tr>
            <tr
              v-for="additionalItem in additionalItems"
              v-bind:key="additionalItem.id"
            >
              <td>{{ additionalItem.description }}</td>
              <td>{{ additionalItem.totalIncl | currency }}</td>
            </tr>
          </tbody>
          <tfoot v-if="!hidePriceProp">
            <tr
              v-if="
                consumablesItem && _.get(consumablesItem, '[0].totalIncl') > 0
              "
            >
              <th class="bordered-top text-end subtitle-2 midGrey--text">
                Consumables
              </th>
              <th class="bordered-top text-end primary--text font-weight-black">
                {{ _.get(consumablesItem, "[0].totalIncl") | currency }}
              </th>
            </tr>
            <tr v-if="adminFeeItem && _.get(adminFeeItem, '[0].totalIncl') > 0">
              <th class="bordered-top text-end subtitle-2 midGrey--text">
                Admin Fee
              </th>
              <td class="bordered-top text-end primary--text font-weight-black">
                {{ _.get(adminFeeItem, "[0].totalIncl") | currency }}
              </td>
            </tr>
            <tr v-if="quoteModel.isVatRegistered">
              <th class="bordered-top text-end subtitle-2 midGrey--text">
                VAT
              </th>
              <th class="bordered-top text-end primary--text font-weight-black">
                {{ totalVat | currency }}
              </th>
            </tr>
            <tr>
              <th class="bordered-top text-end subtitle-2 midGrey--text">
                Total
              </th>
              <th class="bordered-top text-end primary--text font-weight-black">
                {{ getQuoteTotal() | currency }}
              </th>
            </tr>
          </tfoot>
        </table>
      </v-col>
    </v-row>
    <div class="workshop-note" v-if="quoteModel.note">
      <span>{{ quoteProp.note }}</span>
    </div>
    <!-- Quote Messages -->
    <div
      v-if="
        quoteModel &&
        quoteModel.quoteMessages &&
        quoteModel.quoteMessages.length > 0
      "
    >
      <v-card outlined class="mt-4">
        <v-simple-table>
          <tbody>
            <tr v-for="item in quoteModel.quoteMessages" v-bind:key="item.id">
              <td class="text-left">
                {{ item.actorSourceText }}
              </td>
              <td class="text-left">
                {{ item.message }}
              </td>
              <td class="text-left">
                {{ dateHelper.formatDateLong(item.dateCreated) }}
              </td>
              <td>
                <v-dialog
                  content-class="smc-dialog smc-dialog-wide"
                  v-if="item.attachmentNames && item.attachmentNames.length > 0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      rounded
                      small
                      outlined
                    >
                      Attachments
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card class="smc-card-dialog">
                      <v-card-title
                        class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
                      >
                        <span class="mx-auto"> Attachments </span>
                      </v-card-title>
                      <div class="smc-card-top-bar">
                        <v-btn
                          icon
                          color="midGrey"
                          class="float-right"
                          height="32"
                          width="32"
                          @click="dialog.value = false"
                        >
                          <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                      </div>
                      <v-card-text>
                        <attachment-list
                          :itemId="item.uniqueId"
                          :attachmentsProp="getQuoteMessageAttachments(item)"
                          @downloadFile="downloadAttachment"
                        ></attachment-list>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-dialog>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>
    <v-divider class="mt-8"></v-divider>
    <v-row class="py-2">
      <v-col>
        <span v-if="isInvoice">Invoice provided by&nbsp;</span>
        <span v-else>Response provided by </span>
        <strong>Service My Car</strong> on behalf of
        <strong>{{ quoteModel.workshop.name }}</strong
        >.<br />
        <span v-if="quoteModel.workshop.registrationNumber">
          Reg no:
          <strong>{{ quoteModel.workshop.registrationNumber }}</strong
          >.
        </span>
        <span v-if="quoteModel.workshop.taxNumber">
          VAT no:
          <strong>{{ quoteModel.workshop.taxNumber }}</strong>
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import Quote from "@/models/quote";
import User from "@/models/user";
import {
  dateHelper,
  financeHelper,
  quoteStatuses,
  amendmentTypes,
  amendmentStatuses,
} from "@/helpers";
import { quoteService } from "@/services";
import attachmentList from "@/components/attachment-list";

export default {
  name: "quote-details-view",
  props: {
    quoteProp: Quote,
    userProp: User,
    referenceProp: String,
    hidePriceProp: Boolean,
  },
  components: {
    attachmentList,
  },
  data: () => {
    return {
      quoteModel: undefined,
      dateHelper: dateHelper,
      quoteStatuses,
      currentUser: undefined,
      amendmentTypes,
      amendmentStatuses,
    };
  },
  computed: {
    invoiceCompletedDate() {
      let statusId = this.quoteStatuses.invoiced.id;
      let index = _.findLastIndex(this.quoteModel.quoteStatuses, function (x) {
        return x.quoteStatus == statusId;
      });
      if (this.quoteModel.quoteStatuses[index]) {
        return this.quoteModel.quoteStatuses[index].statusDate;
      }
      return "";
    },
    isInvoice() {
      return (
        this.quoteModel.quoteStatus == this.quoteStatuses.invoiced.id ||
        this.quoteModel.quoteStatus == this.quoteStatuses.completed.id ||
        this.quoteModel.quoteStatus == this.quoteStatuses.paid.id
      );
    },
    labourItem() {
      return _.filter(this.quoteModel.quoteItems, { description: "Labour" });
    },
    consumablesItem() {
      return _.filter(this.quoteModel.quoteItems, (item) => {
        return (
          item.description === "Consumables" && item.quoteRequestItemId === null
        );
      });
    },
    adminFeeItem() {
      return _.filter(this.quoteModel.quoteItems, (item) => {
        return (
          item.description === "Admin Fee" && item.quoteRequestItemId === null
        );
      });
    },
    additionalItems() {
      return _.filter(this.quoteModel.quoteItems, function (o) {
        return (
          o.description !== "Labour" &&
          o.description !== "Consumables" &&
          o.description !== "Admin Fee" &&
          o.quoteRequestItemId == null
        );
      });
    },
    totalVat() {
      return financeHelper.determineVat(this.getQuoteTotal());
    },
    isOrganization() {
      if (this.$store.getters["user/isAuthenticated"]) {
        return this.$store.getters["user/isOrganization"];
      }
      return false;
    },
  },
  watch: {
    quoteModel: {
      handler() {
        this.$emit("quoteUpdate", this.quoteModel);
      },
      deep: true,
    },
  },
  async created() {
    this.quoteModel = _.cloneDeep(this.quoteProp);
    var user = await this.$store.dispatch("user/loadUser");
    if (user) {
      this.currentUser = user;
    }
  },
  methods: {
    getQuoteTotal() {
      var vm = this;
      var total = _.sumBy(this.quoteModel.quoteRequestItems, function (item) {
        return vm.getQuoteRequestItemTotal(item);
      });
      if (this.consumablesItem[0]) {
        total += _.get(this.consumablesItem, "[0].totalIncl");
      }
      if (this.adminFeeItem[0]) {
        total += _.get(this.adminFeeItem, "[0].totalIncl");
      }
      return total;
    },
    getQuoteRequestItemTotal(quoteRequestItem) {
      var sum = _.sumBy(quoteRequestItem.quoteItems, function (item) {
        if (
          item.amendmentStatus == amendmentStatuses.pending ||
          item.amendmentStatus == amendmentStatuses.approved ||
          item.amendmentStatus == null
        ) {
          if (item.amendmentType != amendmentTypes.removed) {
            if (
              item.amendmentStatus == amendmentStatuses.pending &&
              quoteRequestItem.amendmentAccepted
            ) {
              return item.totalIncl;
            } else if (
              item.amendmentStatus == amendmentStatuses.pending &&
              !quoteRequestItem.amendmentAccepted
            ) {
              return item.verifiedTotalIncl;
            } else {
              return item.verifiedTotalIncl;
            }
          }
        }
        return 0;
      });

      if (
        quoteRequestItem.labourItem &&
        quoteRequestItem.amendmentType != amendmentTypes.removed &&
        (quoteRequestItem.amendmentStatus == null ||
          quoteRequestItem.amendmentStatus == amendmentStatuses.approved)
      ) {
        if (
          quoteRequestItem.labourItem.amendmentType != amendmentTypes.removed
        ) {
          if (
            quoteRequestItem.labourItem.amendmentStatus ==
              amendmentStatuses.pending &&
            quoteRequestItem.amendmentAccepted
          ) {
            sum += quoteRequestItem.labourItem.totalIncl;
          } else if (
            quoteRequestItem.labourItem.amendmentStatus ==
              amendmentStatuses.pending &&
            !quoteRequestItem.amendmentAccepted
          ) {
            sum += quoteRequestItem.labourItem.verifiedTotalIncl;
          } else {
            sum += quoteRequestItem.labourItem.verifiedTotalIncl;
          }
        }
      }

      return sum;
    },
    getHeadingClass(quoteRequestItem) {
      if (quoteRequestItem.quoteItems.length == 0) {
        return "error-class-heading";
      }
      if (this.hasPendingAmendment(quoteRequestItem)) {
        if (quoteRequestItem.amendmentAccepted == null) {
          return "info-class-heading";
        } else if (quoteRequestItem.amendmentAccepted) {
          return "success-class-heading";
        } else {
          return "error-class-heading";
        }
      }
    },
    getFooterClass(quoteRequestItem) {
      if (this.hasPendingAmendment(quoteRequestItem)) {
        if (quoteRequestItem.amendmentAccepted == null) {
          return "info-class-footer";
        } else if (quoteRequestItem.amendmentAccepted) {
          return "success-class-footer";
        } else {
          return "error-class-footer";
        }
      }
    },
    showItemBody(quoteRequestItem) {
      var vm = this;
      // Status_new - return everything
      if (this.quoteModel.quoteStatus == quoteStatuses.pendingApproval.id) {
        return true;
      }
      // Status_accepted, completed or paid - return everthing that has added quote items
      else if (
        this.quoteModel.quoteStatus == quoteStatuses.inProgress.id ||
        this.quoteModel.quoteStatus == quoteStatuses.invoiced.id ||
        this.quoteModel.quoteStatus == quoteStatuses.booked.id ||
        this.quoteModel.quoteStatus == quoteStatuses.completed.id ||
        this.quoteModel.quoteStatus == quoteStatuses.paid.id
      ) {
        var hasQuoteItems = _.some(
          quoteRequestItem.quoteItems,
          function (item) {
            return (
              item.amendmentType == null ||
              item.amendmentStatus == vm.amendmentStatuses.approved
            );
          }
        );
        return hasQuoteItems;
      }
      // Status_accepted
      else if (
        this.quoteModel.quoteStatus == quoteStatuses.booked.id ||
        this.quoteModel.quoteStatus == quoteStatuses.amendmentPending.id
      ) {
        // return everthing that has accepted added quote items else
        if (quoteRequestItem.quoteItems.length == 0) {
          return false;
        }
        // if pending amendment, return everything that we have left else
        else if (this.hasPendingAmendment(quoteRequestItem)) {
          return true;
        }
        // if no pending amendments, return only everything that is not removed or declined
        else {
          var hasSomeValue = false;
          for (
            var i = 0;
            i < quoteRequestItem.quoteItems.length && !hasSomeValue;
            i++
          ) {
            if (
              quoteRequestItem.quoteItems[i].amendmentType !=
                amendmentTypes.removed &&
              quoteRequestItem.quoteItems[i].amendmentStatus !=
                amendmentStatuses.declined
            ) {
              hasSomeValue = true;
            }
          }
          return hasSomeValue;
        }
      }
    },
    showItem(quoteRequestItem, quoteItem) {
      if (this.hasPendingAmendment(quoteRequestItem)) {
        return true;
      } else {
        return (
          quoteItem.amendmentType != amendmentTypes.removed &&
          quoteItem.amendmentStatus != amendmentStatuses.declined
        );
      }
    },
    getAmendmentTypeBodyClass(quoteRequestItem, quoteItem) {
      var cssClass = "";
      if (this.hasPendingAmendment(quoteRequestItem)) {
        if (quoteRequestItem.amendmentAccepted == null) {
          cssClass += "info-class-body";
        } else if (quoteRequestItem.amendmentAccepted) {
          cssClass += "success-class-body";
        } else {
          cssClass += "error-class-body";
        }
      }
      if (quoteItem.amendmentType == amendmentTypes.removed) {
        cssClass += " amendment-item-removed";
      }
      if (quoteItem.amendmentStatus == amendmentStatuses.declined) {
        cssClass += " amendment-item-declined";
      }
      if (
        (quoteItem.amendmentType == amendmentTypes.added ||
          quoteItem.amendmentType == amendmentTypes.modified) &&
        quoteItem.amendmentStatus == amendmentStatuses.pending &&
        !quoteRequestItem.amendmentAccepted
      ) {
        cssClass += " amendment-item-modified";
      }
      return cssClass;
    },
    getQuoteItemDescription(quoteItem) {
      if (quoteItem.amendmentStatus == this.amendmentStatuses.pending) {
        if (quoteItem.amendmentType == this.amendmentTypes.added) {
          return `${quoteItem.description} - Added`;
        }
        if (quoteItem.amendmentType == amendmentTypes.modified) {
          return `${quoteItem.description} - Modified`;
        }
      }
      return quoteItem.description;
    },
    hasPendingAmendment(quoteRequestItem) {
      return _.some(quoteRequestItem.quoteItems, {
        amendmentStatus: this.amendmentStatuses.pending,
      });
    },

    getQuoteMessageAttachments(quoteMessage) {
      var urls = [];

      if (
        quoteMessage.attachmentNames &&
        quoteMessage.attachmentNames.length > 0
      ) {
        quoteMessage.attachmentNames.forEach((attachmentName) => {
          urls.push(
            `${process.env.VUE_APP_SMC_CONTENT_URL}/quote-note-attachments/${quoteMessage.uniqueId}/${attachmentName}`
          );
        });
      }

      return urls;
    },
    async downloadAttachment(messageId, attachmentName) {
      await quoteService.downloadQuoteMessageAttachment(
        messageId,
        attachmentName
      );
    },
  },
};
</script>

<style scoped>
@import "../assets/scss/components/table.scss";

.quotes-table {
  border-collapse: collapse;
  min-width: 220px;
}

.quotes-table th,
.quotes-table td {
  border: 1px solid grey;
  padding: 2px 5px;
}

.quotes-table th {
  background-color: #eaeaea;
}

table th.bordered-top,
table td.bordered-top {
  border-top: 1px solid grey;
}

table th.bordered-bottom,
table td.bordered-bottom {
  border-bottom: 1px solid grey;
}

table tr.error-class-heading th {
  border-left: 2px solid red;
  border-right: 2px solid red;
}

table tr.error-class-heading td {
  color: red;
  border-top: 2px solid red;
  border-left: 2px solid red;
  border-right: 2px solid red;
}

.display-table tr td.nowrap,
.display-table-mobile tr td.nowrap {
  white-space: nowrap;
}

.display-table-mobile tr.display-table-row td {
  border-bottom: 1px solid #575757;
}

/*
.display-table-mobile
  tbody
  tr:not(.error-class-heading):not(.error-class-body)
  td {
  vertical-align: top;
}
*/

.display-table-mobile
  tbody
  tr:not(.error-class-heading):not(.error-class-body):not(
    .display-table-group-header
  ):not(.info-class-footer):not(.success-class-footer):not(.error-class-footer)
  td:first-child {
  border-right: 1px dashed #bbb;
}

.display-table-mobile tbody tr.display-table-row td {
  border-top: 1px dashed #bbb;
}

.display-table tbody tr.info-class-heading th,
.display-table-mobile tbody tr.info-class-heading th {
  border-left: 2px solid #2196f3;
  border-right: 2px solid #2196f3;
}

.display-table tbody tr.info-class-heading td,
.display-table-mobile tbody tr.info-class-heading td {
  color: #2196f3;
  border-top: 2px solid #2196f3;
  border-left: 2px solid #2196f3;
  border-right: 2px solid #2196f3;
}

.display-table tbody tr.success-class-heading th,
.display-table-mobile tbody tr.success-class-heading th {
  border-left: 2px solid #11c52f;
  border-right: 2px solid #11c52f;
}

.display-table tbody tr.success-class-heading td,
.display-table-mobile tbody tr.success-class-heading td {
  color: #11c52f;
  border-top: 2px solid #11c52f;
  border-left: 2px solid #11c52f;
  border-right: 2px solid #11c52f;
}

.display-table tbody tr.info-class-body td:first-child,
.display-table-mobile tbody tr.info-class-body td:first-child {
  border-left: 2px solid #2196f3;
}

.display-table tbody tr.success-class-body td:first-child,
.display-table-mobile tbody tr.success-class-body td:first-child {
  border-left: 2px solid #11c52f;
}

.display-table tbody tr.error-class-body td:first-child,
.display-table-mobile tbody tr.error-class-body td:first-child {
  border-left: 2px solid red;
}

.display-table tbody tr.info-class-body td:last-child,
.display-table-mobile tbody tr.info-class-body td:last-child {
  border-right: 2px solid #2196f3;
}

.display-table tbody tr.success-class-body td:last-child,
.display-table-mobile tbody tr.success-class-body td:last-child {
  border-right: 2px solid #11c52f;
}

.display-table tbody tr.error-class-body td:last-child,
.display-table-mobile tbody tr.error-class-body td:last-child {
  border-right: 2px solid red;
}

.display-table tbody tr.info-class-footer td,
.display-table-mobile tbody tr.info-class-footer td {
  border-left: 2px solid #2196f3;
  border-right: 2px solid #2196f3;
  border-bottom: 2px solid #2196f3;
}

.display-table tbody tr.success-class-footer td,
.display-table-mobile tbody tr.success-class-footer td {
  border-left: 2px solid #11c52f;
  border-right: 2px solid #11c52f;
  border-bottom: 2px solid #11c52f;
}

.display-table tbody tr.error-class-footer td,
.display-table-mobile tbody tr.error-class-footer td {
  border-left: 2px solid red;
  border-right: 2px solid red;
  border-bottom: 2px solid red;
}

table tr.error-class-spacer td {
  height: 15px;
}

table tr.amendment-item-removed td,
table tr.amendment-item-declined td {
  text-decoration: line-through;
  display: none;
}

table tr.amendment-item-modified td {
  color: #2196f3;
}

div.workshop-note {
  border: 2px solid grey;
  padding: 5px;
  margin-bottom: 5px;
}

table tr.amendment-item-modified td div.workshop-note {
  border: 2px solid #2196f3;
}

div.workshop-note span {
  white-space: break-spaces;
}
</style>

<style lang="scss" scoped>
.display-table {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.display-table-mobile {
  @media screen and (min-width: 601px) {
    display: none;
  }

  @media screen and (max-width: 420px) {
    margin-left: -24px;
    min-width: 100vw;
  }
}
</style>

<style>
.link-box .v-text-field__slot {
  margin-right: 75px;
}

.link-box .v-text-field__slot input {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>