<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          id="createQuote-addressStreetNumber"
          v-model="addressProp.streetNumber"
          label="Street Number"
          outlined
          rounded
          prepend-inner-icon="mdi-home-floor-1"
          readonly
        />
        <v-text-field
          id="createQuote-addressStreetName"
          v-model="addressProp.streetName"
          label="Street Name"
          outlined
          rounded
          prepend-inner-icon="mdi-home"
          readonly
        />
        <v-text-field
          id="createQuote-addressSuburb"
          v-model="addressProp.suburb"
          label="Suburb"
          outlined
          rounded
          prepend-inner-icon="mdi-home-group"
          readonly
        />
        <v-text-field
          id="createQuote-addressCity"
          v-model="addressProp.city"
          label="City"
          outlined
          rounded
          prepend-inner-icon="mdi-city"
          readonly
        />
        <v-text-field
          id="createQuote-addressProvince"
          v-model="addressProp.province"
          label="Province"
          outlined
          rounded
          prepend-inner-icon="mdi-map-search"
          readonly
        />
        <v-text-field
          id="createQuote-addressCountry"
          v-model="addressProp.country"
          label="Country"
          outlined
          rounded
          prepend-inner-icon="mdi-flag"
          readonly
        />
        <v-text-field
          id="createQuote-addressPostalCode"
          v-model="addressProp.postalCode"
          label="Postal Code"
          outlined
          rounded
          prepend-inner-icon="mdi-numeric-1-box-multiple-outline"
          readonly
        />
      </v-col>
      <v-col cols="12" sm="7">
        <gmap-map
          style="width: 100%; height: 575px"
          ref="gmap"
          :center="centerPosition"
          :zoom="zoom"
        >
          <gmap-marker
            v-if="this.place"
            label,
            :animation="4"
            :position="{
                  lat: this.place.geometry.location.lat(),
                  lng: this.place.geometry.location.lng()
                }"
          />
        </gmap-map>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Address from "@/models/address";
import { mapHelper } from "@/helpers";
import { placeService } from "@/services";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "address-view",
  data: () => {
    return {
      centerPosition: mapHelper.defaultCenterPosition(),
      zoom: mapHelper.defaultZoomLevel(),
      autocompleteSessionToken: undefined,
      place: undefined
    };
  },
  props: {
    addressProp: Address,
    quoteIdProp: Number
  },
  computed: {
    google: gmapApi
  },
  async mounted() {
    var vm = this;
    var addressText = `${this.addressProp.streetNumber} ${this.addressProp.streetName}, ${this.addressProp.suburb}, ${this.addressProp.city}, ${this.addressProp.province}, ${this.addressProp.country}, ${this.addressProp.postalCode}`;

    // waiting for the map to load the required google sdk js files.
    // this solution is terrible from start to end.
    // a lot (perhaps all) of these problems will be solved if we start storing [lat,lng] for addresses.
    await setTimeout(async () => {
      if (!this.autocompleteSessionToken) {
        this.autocompleteSessionToken = placeService.getAutocompleteSessionToken();
      }

      await placeService
        .getSuggestions(addressText, this.autocompleteSessionToken)
        .then(async function(res) {
          vm.place = await placeService.getPlace(
            res[0].id,
            vm.autocompleteSessionToken
          );
          vm.centerPosition.lat = vm.place.geometry.location.lat();
          vm.centerPosition.lng = vm.place.geometry.location.lng();
          vm.zoom = 16;
        });
    }, 1000);
  }
};
</script>