<template>
  <ValidationObserver ref="validationObserver">
    <v-form v-model="valid" ref="form" lazy-validation @submit="onSubmit($event)">
      <v-row>
        <v-col>
          <ValidationProvider
            v-slot="{ errors }"
            name="Email address"
            rules="required|email"
          >
            <v-text-field
              id="forgotPassword-email"
              v-model="username"
              :error-messages="errors"
              prepend-inner-icon="mdi-account-circle"
              :readonly="resetSent"
              label="Email Address"
              required
              rounded
              outlined
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-expand-transition>
        <v-row v-if="errorMessage" class="py-3">
          <v-col>
            <span class="error-label error--text">{{ errorMessage }}</span>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-divider v-if="errorMessage"></v-divider>
      <v-alert v-if="resetSent" border="left" color="success" outlined rounded>
        Your password reset email has been sent.
        <br />
        Please follow the instructions in the email to continue.
      </v-alert>
      <v-row class="pt-3">
        <v-col>
          <v-btn
            id="forgotPassword-submit"
            :disabled="resetSent"
            color="primary"
            class="mx-auto smc-medium-button"
            rounded
            type="submit"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            id="login-cancel"
            color="darkGrey"
            rounded
            outlined
            class="smc-medium-button"
            @click="$emit('resetForgotPassword')"
          >
            Back to login
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { authService } from "@/services";
import validate from "@/mixins/validator";
import _ from "lodash";

export default {
  name: "account-forgot-password",
  mixins: [validate],
  data() {
    return {
      valid: false,
      loading: false,
      errorMessage: "",
      resetSent: false,
      username: "",
    };
  },
  props: {
    returnUrlProp: String,
  },
  watch: {
    username: function () {
      this.username = _.trim(this.username);
    },
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      if (await this.$refs.validationObserver.validate()) {
        this.loading = true;
        var result = await authService.forgotPassword(this.username);

        if (result.status == 200) {
          this.resetSent = true;
        }

        this.loading = false;
      }
    }
  },
};
</script>

<style scoped>
@import "../../assets/scss/components/otp.scss";
</style>