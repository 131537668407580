<template>
  <v-card color="white" class="smc-card-dialog" elevation="6">
    <div></div>
    <v-card-title class="smc-card-dialog-title-inset primary white--text">
      <span class="mx-auto">{{ quoteRequestProp.reference }}</span>
    </v-card-title>
    <v-card-subtitle class="smc-card-dialog-subtitle-inset">
      <span class="font-weight-medium">
        {{ dateHelper.formatDateLong(quoteRequestProp.dateCreatedUtc) }}
      </span>
    </v-card-subtitle>
    <v-card-text>
      <v-row class="mt-4">
        <v-col>
          <div class="smc-card-avatar">
            <router-link :to="`/quotes/${quoteRequestProp.uniqueId}`">
              <img
                :src="quoteRequestProp.vehicle.imageUrl"
                class="smc-search-result-image"
              />
            </router-link>
          </div>
        </v-col>
        <v-col style="text-align: left">
          <v-label>{{ quoteRequestProp.vehicle.year }}</v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicle.brand }}</v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicle.range }}</v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicle.model }}</v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicle.transmissionType }}</v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicle.fuelType }}</v-label>
        </v-col>
        <v-divider vertical role="presentation" />
        <v-col>
          <v-icon color="primary">mdi-map-marker</v-icon>
          <br />
          <v-label>{{
            `${
              quoteRequestProp.address.streetNumber
                ? quoteRequestProp.address.streetNumber
                : ""
            } ${
              quoteRequestProp.address.streetName
                ? quoteRequestProp.address.streetName
                : ""
            }`
          }}</v-label>
          <br />
          <v-label>{{
            quoteRequestProp.address.suburb
              ? quoteRequestProp.address.suburb
              : ""
          }}</v-label>
          <br />
          <v-label>{{
            quoteRequestProp.address.city ? quoteRequestProp.address.city : ""
          }}</v-label>
          <br />
          <v-label>{{
            quoteRequestProp.address.province
              ? quoteRequestProp.address.province
              : ""
          }}</v-label>
          <br />
          <v-label>{{
            quoteRequestProp.address.postalCode
              ? quoteRequestProp.address.postalCode
              : ""
          }}</v-label>
        </v-col>
        <v-divider vertical role="presentation" />
        <v-col>
          <v-sheet v-if="_.size(quoteRequestProp.quotes) > 0">
          <p v-if="!isInvoice" class="title font-weight-bold">
            Responses:
            <span class="primary--text">{{
              _.size(quoteRequestProp.quotes, "length")
            }}</span>
          </p>
          <v-btn
            :to="`/quotes/${quoteRequestProp.uniqueId}`"
            color="primary"
            rounded
            outlined
          >
            <span v-if="isInvoice">View Invoice</span>
            <span v-else>View</span>
          </v-btn>
          <v-chip class="mt-6" color="primary" v-if="allQuotesExpired">
            All responses have expired
            <v-icon class="ml-2">mdi-alert</v-icon>
          </v-chip>
          <p v-if="isInvoice" class="mt-4">Click here to view your invoice.</p>
          <p v-else class="mt-4">Click here to view all responses.</p>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import QuoteRequest from "@/models/quote-request";
import { dateHelper, quoteStatuses } from "@/helpers";

export default {
  name: "quote-request-view",
  data: () => {
    return {
      dateHelper,
      quoteStatuses,
    };
  },
  props: {
    quoteRequestProp: QuoteRequest,
  },
  computed: {
    isInvoice() {
      var isCompletedOrPaid = (element) =>
        element.quoteStatus == this.quoteStatuses.invoiced.id ||
        element.quoteStatus == this.quoteStatuses.completed.id ||
        element.quoteStatus == this.quoteStatuses.paid.id;
      return this.quoteRequestProp.quotes.some(isCompletedOrPaid);
    },
    allQuotesExpired() {
      var allExpired = false;

      if (!this.$store.getters["user/isOrganization"]) {
        if (this.quoteRequestProp.quotes.length > 0) {
          allExpired = !_.some(this.quoteRequestProp.quotes, {
            isExpired: false,
          });
        }
      }

      return allExpired;
    },
  },
};
</script>

<style lang="scss" scoped>
.smc-card-dialog.v-card > .v-card__title.smc-card-dialog-title-inset {
  @media screen and (max-width: 600px) {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 30px;
  }
}

.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}
</style>