<template>
  <div class="home container">
    <background-parallax v-bind:backgroundOpacityProp="'0.6'" />
    <v-card style="margin-top: 10px; padding-bottom: 10px" color="white">
      <v-card color="green">
        <v-card-text style="color: white">
          <v-icon x-large style="color: white">mdi-check-circle</v-icon>
        </v-card-text>
        <v-card-text style="color: white">
          <h1>Thank You!</h1>
          <p style="padding-top: 15px">Your payment was successful.</p>
        </v-card-text>
      </v-card>
      <v-card-text style="text-align: left">
        <p>
          You have successfully paid for your service/repair to the workshop.
        </p>
        <p>
          The workshop will be notified of this payment and your vehicle is now
          ready to be picked up.
        </p>
        <p>
          Click <router-link to="/quote-requests">here</router-link> to view
          your quote history.
        </p>
        <p>
          For enquiries, please contact our
          <a :href="contactEmail">customer support team</a>.
        </p>
      </v-card-text>
      <v-btn to="/home" color="primary" rounded class="smc-medium-button">
        Continue
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import backgroundParallax from "@/components/widgets/background-parallax";
export default {
  data: () => {
    return {
      contactEmail: "mailto:" + process.env.VUE_APP_CONTACT_EMAIL,
    };
  },
  components: {
    backgroundParallax,
  },
};
</script>