<template>
  <div :class="containerClass">
    <background-parallax
      v-bind:srcProp="
        isOrganization
          ? require('@/assets/images/background-images/wbc-banner-clean.jpg')
          : ''
      "
      v-bind:backgroundOpacityProp="'0.4'"
    />
    <div class="mt-12 pt-12" style="position: relative"></div>
    <!--<v-sheet
      v-if="isOrganization && dashboardUrl"
      class="mb-12 pb-0 box zoho-embed-container"
      elevation="6"
    >
      <iframe class="zoho-embed-frame" style="width: 100%; height: 100%; border-radius: 15px;" :src="dashboardUrl"></iframe>
    </v-sheet>
    v-else
    -->
    <v-sheet
      class="mb-12 py-12 box box-rounded box-transparent box-transparent-dark"
      elevation="6"
    >
    
      <!--<h1 class="page-header display-1 font-weight-medium white--text">
        Active Requests - {{ organizationBranch.branchName }}
      </h1>
      -->
      <h1 class="page-header display-1 font-weight-medium white--text">
        Active Requests<span v-if="isOrganization"> - {{ organizationBranch.branchName }}</span>
      </h1>
    </v-sheet>
    <v-card
      class="box box-rounded box-transparent box-transparent-light"
      elevation="12"
    >
      <v-card-subtitle>
        <v-breadcrumbs class="smc-breadcrumbs">
          <v-breadcrumbs-item class="smc-breadcrumbs-item" to="/">
            <v-icon>mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider v-if="!isOrganization">
            /
          </v-breadcrumbs-divider>
          <v-breadcrumbs-item
            class="smc-breadcrumbs-item"
            to="/my-garage"
            v-if="!isOrganization"
          >
            My Garage
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item class="smc-breadcrumbs-item">
            Active Requests
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </v-card-subtitle>

      <v-card-text>
        <v-sheet class="active-work-tab-container">
          <v-tabs
            v-model="currentTab"
            fixed-tabs
            center-active
            centered
            background-color="primary"
            active-class="white red--text border"
            slider-color="transparent"
          >
            <v-tab :class="!isOrganization ? 'd-none' : ''">Vehicles</v-tab>
            <v-tab> Pending </v-tab>
            <v-tab> Booked </v-tab>
            <v-tab> In Progress </v-tab>
            <v-tab> Ready for Collection </v-tab>
            <v-tab> Completed </v-tab>

            <v-tab-item :class="!isOrganization ? 'd-none' : ''">
              <my-garage-tab></my-garage-tab>
            </v-tab-item>
            <v-tab-item>
              <active-request-tab
                v-if="currentTab == 1"
                v-bind:stageProp="'1'"
              ></active-request-tab>
            </v-tab-item>
            <v-tab-item>
              <active-request-tab
                v-if="currentTab == 2"
                v-bind:stageProp="'2'"
              ></active-request-tab>
            </v-tab-item>
            <v-tab-item>
              <active-request-tab
                v-if="currentTab == 3"
                v-bind:stageProp="'3'"
              ></active-request-tab>
            </v-tab-item>
            <v-tab-item>
              <active-request-tab
                v-if="currentTab == 4"
                v-bind:stageProp="'4'"
              ></active-request-tab>
            </v-tab-item>
            <v-tab-item>
              <active-request-tab
                v-if="currentTab == 5"
                v-bind:stageProp="'5'"
              ></active-request-tab>
            </v-tab-item>
          </v-tabs>
        </v-sheet>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import backgroundParallax from "@/components/widgets/background-parallax";
import activeRequestTab from "@/components/active-request-tab";
import myGarageTab from "@/components/my-garage-tab";

export default {
  name: "active-requests",
  components: {
    backgroundParallax,
    activeRequestTab,
    myGarageTab,
  },
  data: () => ({
    currentTab: 0,
  }),
  created() {
    if (!this.isOrganization) {
      this.currentTab = 1;
    }
    if (this.$route.query.tab) {
      this.currentTab = parseInt(this.$route.query.tab) - 1;
    }
  },
  computed: {
    isOrganization() {
      return this.$store.getters["user/isOrganization"];
    },
    organization() {
      return this.$store.getters["user/getOrganization"];
    },
    organizationBranch() {
      return this.organization.organizationBranches[0];
    },
    containerClass() {
      let css = "container";
      if (this.$vuetify.breakpoint.xsOnly) {
        css = `${css} px-0`;
      }
      if (this.isOrganization) {
        css = `${css} home-wide`;
      } else {
        css = `${css} home`;
      }
      return css;
    },
    dashboardUrl() {
      switch (this.organizationBranch.code) {
        case "PEL":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Gqeberha (EC)'`;
        case "MID":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Midstream (GP)'`;
        case "JBS":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'JHB South (GP)'`;
        case "SIL":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Silver Lakes (GP)'`;
        case "GER":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Germiston (GP)'`;
        case "DOM":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Dome (GP)'`;
        case "DBN":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Springfield (KZN)'`;
        case "RIV":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Riverhorse (KZN)'`;
        case "PMB":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Pietermaritzburg (KZN)'`;
        case "POL":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Polokwane (L)'`;
        case "NEL":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Polokwane (L)'`;
        case "CPT":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Brackenfell (WC)'`;
        case "EPP":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Epping (WC)'`;
        case "GEO":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'George (WC)'`;
        case "RIC":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e?ZOHO_CRITERIA="wbc_dashboard_data"."We Buy Cars Branch" = 'Richmond (WC)'`;
        case "TST":
          return `https://analytics.zoho.com/open-view/2576060000008196017/7132e4ab687b3648725108cb15a42d0e`;
        default:
          return undefined;
      }
    }
  },
  watch: {
    currentTab(newVal) {
      history.pushState({}, null, `/active-requests?tab=${newVal + 1}`);
    },
  },
};
</script>

<style scoped>
.active-work-tab-container,
.active-work-tab-container .v-tabs {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}

div.home-wide.container {
  max-width: 1500px;
}

.zoho-embed-container {
  height: 320px; 
  border-radius: 15px; 
  background-color: #757575;
}

.zoho-embed-frame {
  border: none;
  border-radius: 15px; 
  border: 1px solid #b3b3b3;
}
</style>

<style>
.active-work-tab-container .v-slide-group {
  height: 60px;
  padding: 10px;
}

.active-work-tab-container .v-tab--active {
  border-radius: 15px;
}
</style>