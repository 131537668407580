import { render, staticRenderFns } from "./active-requests.vue?vue&type=template&id=279f2a64&scoped=true&"
import script from "./active-requests.vue?vue&type=script&lang=js&"
export * from "./active-requests.vue?vue&type=script&lang=js&"
import style0 from "./active-requests.vue?vue&type=style&index=0&id=279f2a64&scoped=true&lang=css&"
import style1 from "./active-requests.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "279f2a64",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsDivider } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBreadcrumbs,VBreadcrumbsDivider,VBreadcrumbsItem,VCard,VCardSubtitle,VCardText,VIcon,VSheet,VTab,VTabItem,VTabs})
