<template>
    <div v-viewer class="v-viewer">
        <v-row>
            <v-col cols="4" v-for="(attachment, index) in modifiedAttachments" :key="`qna_${index}`">
                <div style="border: 1px solid grey" class="text-center">
                    <template v-if="attachment.isImage">
                        <img style="max-width: 100%" class="cursor-pointer" :src="attachment.originalSrc" />
                    </template>
                    <template v-else>
                        <span>
                            <v-icon style="font-size: 160px;">{{ attachment.displayIcon }}</v-icon>
                            <br/>
                            {{ attachment.filename }}
                        </span>
                    </template>
                    <v-divider></v-divider>
                    <v-btn class="my-2" color="secondary" @click="downloadFile(attachment.filename)">Download</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "attachment-list",
    props: {
        itemId: String,
        attachmentsProp: Array
    },
    data: () => ({
        imageExtensions: ['jpg', 'jpeg', 'png', 'gif', 'webp']
    }),
    computed: {
        modifiedAttachments() {
            return this.attachmentsProp.map((src) => {
                const fileExtension = src.split('.').pop().toLowerCase();

                if (this.imageExtensions.includes(fileExtension)) {
                    return { 
                        originalSrc: src, 
                        isImage: true,
                        filename: this.getFilename(src)
                    };
                } else {
                    return {
                        originalSrc: src,
                        displayIcon: this.getFileTypeIcon(fileExtension),
                        isImage: false,
                        filename: this.getFilename(src)
                    };
                }
            });
        }
    },
    methods: {
        getFileTypeIcon(fileExtension) {
            const isPdf = fileExtension === 'pdf';
            const isExcel = /(xls|xlsx)/.test(fileExtension);
            const isWord = /(doc|docx)/.test(fileExtension);
            const isText = fileExtension === 'txt';

            if (isPdf) {
                return 'mdi-file-pdf-box';
            } else if (isExcel) {
                return 'mdi-file-excel-box';
            } else if (isWord) {
                return 'mdi-file-word-box';
            } else if (isText) {
                return 'mdi-file-document-outline';
            } else {
                return 'mdi-file-outline'; // Default icon for other file types
            }
        },
        getFilename(url) {
            // Extract the filename from the URL
            return url.split('/').pop();
        },
        downloadFile(filename) {
            this.$emit("downloadFile", this.itemId, filename);
        }
    }
}
</script>

<style>
.v-viewer {
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

</style>
