<template>
  <v-card color="white" class="smc-card-dialog">
    <div></div>
    <v-card-title class="smc-card-dialog-title-inset primary white--text">
      <span class="mx-auto">{{ quoteRequestProp.reference }}</span>
    </v-card-title>
    <v-card-subtitle class="smc-card-dialog-subtitle-inset">
      <span class="font-weight-medium">
        {{ dateHelper.formatDateLong(quoteRequestProp.dateCreatedUtc) }}
      </span>
    </v-card-subtitle>
    <v-card-text>
      <v-row class="mt-4">
        <v-col sm="6" md="4">
          <div class="smc-card-avatar">
            <img
              :src="quoteRequestProp.vehicle.imageUrl"
              class="smc-search-result-image"
            />
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3" style="text-align: left">
          <v-label>{{ quoteRequestProp.vehicle.year }}</v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicle.brand }}</v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicle.range }}</v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicle.model }}</v-label>
          <br />
          <v-label>
            <strong>Mileage:</strong>
            {{ quoteRequestProp.vehicleMileage }} km
          </v-label>
        </v-col>
        <!--
          <v-divider vertical role="presentation" />
          <v-col style="text-align: left;">
          <div v-if="quoteRequestProp.licenseDiskPhotoUrl">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on" class="horizontal-center">mdi-image</v-icon>
              </template>
              <v-img
                :src="quoteRequestProp.licenseDiskPhotoUrl"
                :lazy-src="quoteRequestProp.licenseDiskPhotoUrl"
                contain
                max-width="600"
              ></v-img>
            </v-tooltip>
            <br />
          </div>
          <v-label>
            <strong>VIN number</strong>
          </v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicleVinNumber }}</v-label>
          <br />
          <v-label>
            <strong>Engine number</strong>
          </v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicleEngineNumber }}</v-label>
          <br />
          <v-label>
            <strong>License plate</strong>
          </v-label>
          <br />
          <v-label>{{ quoteRequestProp.vehicleLicensePlate }}</v-label>
          <br />
        </v-col>-->
        <v-divider
          v-if="$vuetify.breakpoint.smAndUp"
          vertical
          role="presentation"
        />
        <v-col>
          <v-icon color="primary">mdi-map-marker</v-icon>
          <br />
          <v-label>{{
            `${
              quoteRequestProp.address.streetNumber
                ? quoteRequestProp.address.streetNumber
                : ""
            } ${
              quoteRequestProp.address.streetName
                ? quoteRequestProp.address.streetName
                : ""
            }`
          }}</v-label>
          <br />
          <v-label>{{
            quoteRequestProp.address.suburb
              ? quoteRequestProp.address.suburb
              : ""
          }}</v-label>
          <br />
          <v-label>{{
            quoteRequestProp.address.city ? quoteRequestProp.address.city : ""
          }}</v-label>
          <br />
          <v-label>{{
            quoteRequestProp.address.province
              ? quoteRequestProp.address.province
              : ""
          }}</v-label>
          <br />
          <v-label>{{
            quoteRequestProp.address.postalCode
              ? quoteRequestProp.address.postalCode
              : ""
          }}</v-label>
          <br />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import QuoteRequest from "@/models/quote-request";
import { dateHelper } from "@/helpers";

export default {
  name: "quote-request-summary",
  data: () => {
    return {
      dateHelper: dateHelper,
    };
  },
  props: {
    quoteRequestProp: QuoteRequest,
  },
};
</script>

<style lang="scss" scoped>
.smc-card-avatar {
  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.smc-card-dialog.v-card > .v-card__title.smc-card-dialog-title-inset {
  @media screen and (max-width: 600px) {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 30px;
  }
}

.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}
</style>