<template>
    <div :id="`directions-content-${quoteIdProp}`">
      <v-row>
        <v-col cols="12" sm="7">
          <gmap-map
            style="width: 100%; height: 575px"
            ref="gmap"
            :center="centerPosition"
            :zoom="zoom"
          ></gmap-map>
        </v-col>
        <v-col>
          <div
            v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
            style="height: 575px; overflow: scroll;"
          >
            <div :id="`directions-panel-${quoteIdProp}`"></div>
          </div>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import Address from "@/models/address";
import { mapHelper } from "@/helpers";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "address-route-view",
  data: () => {
    return {
      centerPosition: mapHelper.defaultCenterPosition(),
      zoom: mapHelper.defaultZoomLevel(),
      autocompleteSessionToken: undefined,
      fromPlace: undefined,
      toPlace: undefined
    };
  },
  props: {
    fromAddressProp: Address,
    toAddressProp: Address,
    quoteIdProp: Number
  },
  computed: {
    google: gmapApi
  },
  async mounted() {
    var vm = this;
    var fromAddressText = `${this.fromAddressProp.streetNumber} ${this.fromAddressProp.streetName}, ${this.fromAddressProp.suburb}, ${this.fromAddressProp.city}, ${this.fromAddressProp.province}, ${this.fromAddressProp.country}, ${this.fromAddressProp.postalCode}`;
    var toAddressText = {lat:this.toAddressProp.lat, lng:this.toAddressProp.lng};
    // waiting for the map to load the required google sdk js files.
    // this solution is slightly less terrible, but cannot be fixed by storing address [lat,lng].
    await setTimeout(async () => {
      var directionsService = new vm.google.maps.DirectionsService();
      var directionsDisplay = new vm.google.maps.DirectionsRenderer();
      directionsDisplay.setMap(vm.$refs.gmap.$mapObject);
      directionsDisplay.setPanel(document.getElementById(`directions-panel-${this.quoteIdProp}`));

      new vm.google.maps.DirectionsService().route(
        {
          origin: fromAddressText,
          destination: toAddressText,
          travelMode: "DRIVING"
        },
        function(response, status) {
          if (status === "OK") {
            directionsDisplay.setDirections(response);
          }
        }
      );
    }, 1000);
  }
};
</script>

<style>
table.adp-placemark {
  margin-top: 0;
}
</style>