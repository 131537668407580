<template>
  <v-sheet id="dashboard-container" class="mt-6">
    <div ref="iframeContainer" :style="`height: ${usableHeight}px;`">
      <iframe
        ref="dashboardIframe"
        frameborder="0"
        width="100%"
        height="100%"
        :src="dashboardUrl"
      ></iframe>
    </div>
  </v-sheet>
</template>
  
<script>
export default {
  name: "dashboard",
  data: () => ({
    usableHeight: 0,
  }),
  computed: {
    isOrganization() {
      return this.$store.getters["user/isOrganization"];
    },
    organization() {
      return this.$store.getters["user/getOrganization"];
    },
    isOrganizationAdmin() {
      return this.$store.getters["user/isOrganizationAdmin"];
    },
    organizationBranch() {
      return this.organization.organizationBranches[0];
    },
    dashboardUrl() {
      if (this.isOrganizationAdmin) {
        return "https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a";
      }
      switch (this.organizationBranch.code) {
        case "PEL":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Gqeberha (EC)'`;
        case "MID":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Midstream (GP)'`;
        case "JBS":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'JHB South (GP)'`;
        case "SIL":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Silver Lakes (GP)'`;
        case "GER":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Germiston (GP)'`;
        case "DOM":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Dome (GP)'`;
        case "DBN":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Springfield (KZN)'`;
        case "RIV":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Riverhorse (KZN)'`;
        case "PMB":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Pietermaritzburg (KZN)'`;
        case "POL":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Polokwane (L)'`;
        case "NEL":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Polokwane (L)'`;
        case "CPT":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Brackenfell (WC)'`;
        case "EPP":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Epping (WC)'`;
        case "GEO":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'George (WC)'`;
        case "RIC":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a?ZOHO_CRITERIA="!Step3_(New)_Clean_Table"."organizationBranch.branchName" = 'Richmond (WC)'`;
        case "TST":
          return `https://analytics.zoho.com/open-view/2576060000007994346/31d13e2cac41b1e3c88ac70c9a24de3a`;
        default:
          return undefined;
      }
    },
  },
  mounted() {
    this.calculateUsableHeight();
    window.addEventListener("resize", this.calculateUsableHeight);

    // Listen for postMessage events from the iframe
    window.addEventListener("message", (event) => {
      if (event.data && event.data.type === "adjustHeightResponse") {
        const iframe = this.$refs.dashboardIframe;

        // Set the height of the iframe based on the received data
        iframe.style.height = event.data.height + "px";
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateUsableHeight);
  },
  methods: {
    calculateUsableHeight() {
      const headerComponent = document.getElementById("smc-app-header");
      const dashboardContainer = document.getElementById("dashboard-container");

      const headerHeight = headerComponent.offsetHeight;
      const containerRect = dashboardContainer.getBoundingClientRect();

      this.usableHeight = window.innerHeight - containerRect.top - headerHeight;
    },
  },
};
</script>
  
<style>
.container {
    max-width: 2200px;
  }
</style>